<template>
  <div v-if="!renderless_mode">
    <b-card header-class="p-2 px-3 p-sm-2 px-sm-3" no-body>
      <div slot="header">
        <strong>{{ title }}</strong>
        <div class="card-header-actions">
          <EditableCardButtons
            :is_view_only="is_view_only"
            :is_editable.sync="is_editable"
            :is_always_editable="is_always_editable_"
            :disabled="loading || disabled"
            :is_submittable="is_submittable"
            @submit="submit()"
            @get="get()"
          />
        </div>
      </div>
      <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
      <!-- {{relationship}} -->
      <div class="p-2 p-sm-3">
        <div v-if="error_message" v-html="error_message" class="alert alert-danger"></div>
        <div v-if="success_message" v-html="success_message" class="alert alert-success"></div>
        <!-- {{active_params}} -->
        <template v-if="properties">
          <!-- <PropertiesView
            :properties="properties"
            :params.sync="active_params"
            :disabled="is_disabled || loading"
            :with_virtual="true"
          ></PropertiesView>-->
          <TabView
            :class_name="class_name"
            :params.sync="active_params"
            :disabled="disabled || is_disabled || loading"
            :allow_mysql_view="true"
            :allow_columns="false"
            :only_dropdown_relationships="only_dropdown_relationships"
            :with_virtual_properties="with_virtual_properties"
            :only_property_keys="with_properties ? with_properties : null"
            :only_relationship_names="with_relationships.map(relationship => relationship['relationship_name'])"
          ></TabView>
        </template>
        <template v-if="relationship">
          <template v-if="relationship['recursive']">
            <RecursiveView
              :relationship="relationship"
              :params.sync="active_params"
              :view_only="disabled || is_disabled || loading || $d.relIsViewOnly(relationship)"
              :mode="'flattened'"
              :find_button_visibility="find_button_visibility"
              :show_action_bar="_.get(find_button_visibility, [relationship['relationship_name']], true)"
              :reverse_action_bar="reverse_action_bar"
              :small="small"
              :only_dropdown_relationships="only_dropdown_relationships"
              :_index_params="selecting_index_params"
            >
              <!-- only_dropdown_relationships is used to Make it A Habit not to go deep -->
              <template slot="modal-header-buttons" slot-scope="{ close }">
                <EditableCardButtons
                  :is_editable.sync="is_editable"
                  :is_always_editable="is_always_editable_"
                  :disabled="disabled || loading"
                  :is_submittable="is_submittable"
                  @submit="submit()"
                  @get="get()"
                >
                  <!-- <button type="button" class="close text-light" @click="close()">×</button> -->
                  <b-button variant="dark" @click="close()">
                    Close &nbsp;
                    <i class="fas fa-times"></i>
                  </b-button>
                </EditableCardButtons>
              </template>
              <!-- <template slot="modal-footer">
                <EditableCardButtons :is_editable.sync="is_editable" :disabled="loading"  @submit="submit()" @get="get()"/>
              </template>-->
            </RecursiveView>
          </template>
          <template v-else>
            <RelationshipView
              ref="relationship_view"
              :view_only="disabled || is_disabled || loading || $d.relIsViewOnly(relationship)"
              :fixed_params="fixed_params"
              :relationship="relationship"
              :params.sync="active_params"
              :show_selecting_search="true"
              :small="small"
              :_index_params="selecting_index_params"
            ></RelationshipView>
            <!-- :show_selecting_search="active_params['id'] ? false : (!fixed_params || fixed_params[$d.getRelationAlias(relationship)] == null)" -->
          </template>
        </template>
      </div>
    </b-card>
  </div>
  <div v-else>
    <!-- <EditableCardButtons :is_editable.sync="is_editable" :disabled="loading" @submit="submit()" @get="get()"/> -->
    <slot
      v-bind="{
        is_editable,
        is_always_editable_,
        mutate,
        is_disabled,
        loading,
        active_params,
        params,
        error_message,
        success_message,
        add,
        remove,
        submit,
        get,
      }"
    ></slot>
  </div>
</template>

<script>
import { QLinearProgress } from 'quasar';

import _ from 'lodash';
export default {
  components: {
    QLinearProgress,
    EditableCardButtons: () => import('@/descriptor/coreui/EditableCardButtons'),
    TabView: () => import('@/descriptor/coreui/components/modal/TabView'),
    PropertiesView: () => import('@/descriptor/coreui/components/modal/PropertiesView'),
    RecursiveView: () => import('@/descriptor/coreui/components/modal/RecursiveView'),
    RelationshipView: () => import('@/descriptor/coreui/components/modal/RelationshipView'),
  },
  props: {
    title: {
      type: String,
    },
    class_name: {
      type: String,
    },
    relationship: {
      type: Object,
    },
    relationship_index_params: {
      type: Object,
    },
    direction: {
      type: String,
    },
    params: {
      // type: Object,
      required: true,
    },
    //the below are added only for compatibility sake, not sure if actually needed
    find_button_visibility: {
      type: Object,
    },
    fixed_params: {
      type: Object,
    },
    _index_params: {
      type: Object,
    },
    small: {
      type: Boolean,
      default: true,
    },
    renderless_mode: {
      type: Boolean,
      default: false,
    },
    with_virtual_properties: {
      default: true,
    },
    only_dropdown_relationships: {
      type: Boolean,
      default: true,
    },
    with_properties: {
      type: Array,
    },
    with_relationships: {
      type: Array,
    },
    is_always_editable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverse_action_bar: {
      type: Boolean,
      default: true,
    },
    _is_editable: {},
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      loading: null,

      is_editable_: false,
      is_disabled: true,

      params_: null,
      editable_params: null,
    };
  },
  computed: {
    class_() {
      if (this.class_name) {
        return this.$d.getClass(this.class_name);
      }
      return null;
    },
    properties() {
      return _.get(this.class_, ['properties'], null);
    },
    source_class_name() {
      let source_direction = this.$d.invertRelationshipDirection(this.direction || 'to');
      console.log('source_direction', source_direction);
      return _.get(this.relationship, [source_direction, 'class_name'], null);
    },
    relation_alias() {
      if (!_.isNil(this.relationship)) {
        return this.$d.getRelationAlias(this.relationship);
      }
      return null;
    },
    submit_index_params_() {
      let submit_index_params_ = {};

      let temp_filter_params = {};
      temp_filter_params['with_properties'] = [];
      temp_filter_params['with_relationships'] = [];

      if (!_.isNil(this.relationship)) {
        let with_relationships_params = {
          from_class_name: this.relationship['from']['class_name'],
          relationship_name: this.relationship['relationship_name'],
          direction: this.direction || 'to',
          // with_virtual_properties: true, //IMPORTANT TODO: specify which columns needed
          // index_withs: false,
          // with_relationships: [], //IMPORTANT TODO: specify which deeper relationships needed
        };
        if (this.relationship_index_params) {
          with_relationships_params = Object.assign(with_relationships_params, this.relationship_index_params);
        }
        temp_filter_params['with_relationships'].push(with_relationships_params);
      }

      if (!_.isNil(this.with_relationships)) {
        //sanitize
        temp_filter_params['with_relationships'] = temp_filter_params['with_relationships'].concat(this.with_relationships);
      }

      if (!_.isNil(this.with_properties)) {
        temp_filter_params['with_properties'] = temp_filter_params['with_properties'].concat(this.with_properties);
      }

      // console.log("temp_filter_params['with_relationships']", temp_filter_params['with_relationships']);
      this.$d.mergeNonFilterParams(submit_index_params_, temp_filter_params);

      if (this._index_params) {
        submit_index_params_ = Object.assign(submit_index_params_, this._index_params);
      }
      // console.log('submit_index_params_', submit_index_params_);
      return submit_index_params_;
    },
    selecting_index_params() {
      let selecting_index_params = {};
      selecting_index_params = _.merge(selecting_index_params, this.$d.getRelationToSearch(this.relationship, this.params, null, this.direction || 'to'));

      return selecting_index_params;
    },
    is_view_only() {
      let is_view_only = false;
      is_view_only = is_view_only || this.$d.relIsViewOnly(this.relationship);

      return is_view_only;
    },
    active_params: {
      // getter
      get: function() {
        if (!this.is_always_editable_) {
          // return this.editable_params;
          if (this.is_editable && !this.is_disabled) {
            return this.editable_params;
          }
          return this.params_;
        }
        return this.params;
      },
      // setter
      set: function(newValue) {
        if (!this.is_always_editable_) {
          if (this.is_editable && !this.is_disabled) {
            console.log('set active_params');
            this.editable_params = newValue;
            this.$set(this, 'editable_params', newValue);
          }
          console.log('dropped active_params');
        } else {
          this.params = newValue;
        }
      },
    },
    is_editable: {
      get: function() {
        if (typeof this._is_editable != 'undefined') {
          return this._is_editable;
        }

        return this.is_editable_;
      },
      // setter
      set: function(newValue) {
        this.is_editable_ = newValue;
        this.$emit('update:_is_editable', newValue);
      },
    },
    is_submittable() {
      if (!this.params['id']) {
        return false;
      }
      return true;
    },
    is_always_editable_() {
      if (_.isNil(this.params['id'])) {
        return true;
      }

      return this.is_always_editable;
    },
  },
  watch: {
    /* params: {
      immediate: true,
      handler(to, from) {
        if (!this.is_editable) {
          console.log('snapshot only editable bitssss');
          this.editable_params = Object.assign({}, this.params);
        }
      }
    }, */
    params: {
      //extra layer of pretection
      deep: true,
      immediate: true,
      handler(to, from) {
        if (!_.isEqual(to, from)) {
          this.params_ = _.cloneDeep(to);
        }
      },
    },
    is_editable(to, from) {
      if (to == false) {
        //cancelled
        this.is_disabled = true;
        this.error_message = null;
      }
      if (from == false && to == true) {
        //edge detect, from not editable to editable

        this.editable_params = this.extract(this.editable_params, this.params_);
        this.is_disabled = false;
      }
    },
  },
  created() {
    this._ = _;
    /* this.$on('update:is_editable', function(val) {
      console.log('editablee');
      this.is_editable = val;
    }); */

    if (this.is_always_editable_) {
      this.is_editable = true;
    }

    if (this.params['id']) {
      //TODO: check if deep items are retrieved
      console.log('submit_index_params_', this.submit_index_params_);
      if (!this.$d.isWithRelationshipsSatisfied(this.params, this.submit_index_params_['with_relationships'])) {
        this.get();
      }
    }
  },
  methods: {
    mutate(prop, val, callback) {
      this[prop] = val;
      if (callback) {
        callback();
      }
    },
    add(index = null, position = 'down') {
      if (!this.is_disabled) {
        console.log('addRelation');
        if (!Array.isArray(this.active_params[this.relation_alias])) {
          this.active_params[this.relation_alias] = [];
        }

        var item = {};
        item[this.relationship['relationship_key']] = {}; //initialise properties of a relationship
        let added_item_index = null;
        if (index === null) {
          if (position == 'down') {
            this.active_params[this.relation_alias].push(item); //put last
            added_item_index = this.active_params[this.relation_alias].length - 1;
          } else if (position == 'up') {
            this.active_params[this.relation_alias].unshift(item); //put first
            added_item_index = 0;
          }
        } else {
          // this.active_params[this.relation_alias].splice(index + 1, 0, item); //put below
          // added_item_index = index + 1;

          if (position == 'down') {
            this.active_params[this.relation_alias].splice(index + 1, 0, item); //put below
            added_item_index = index + 1;
          } else if (position == 'up') {
            if (index == 0 || this.active_params[this.relation_alias].length == 0) {
              this.active_params[this.relation_alias].unshift(item); //put first
              added_item_index = 0;
            } else {
              this.active_params[this.relation_alias].splice(index, 0, item); //put below
              added_item_index = index;
            }
          }
        }
      } else {
        console.error('not allowed to edit when disabled');
      }
    },
    remove(index) {
      if (!this.is_disabled) {
        console.log('removeRelation');
        this.active_params[this.relation_alias].splice(index, 1);
      } else {
        console.error('not allowed to edit when disabled');
      }
    },
    extract(to_params, from_params) {
      console.log('snapshot only editable bits', this.properties);
      // to_params = Object.assign({}, from_params); //only copies at the surface

      to_params = {};

      to_params['id'] = _.cloneDeep(from_params['id']); //required to ensure the behaviour is similar
      this.$set(to_params, 'id', _.cloneDeep(from_params['id']));

      //TODO: need to find a good way to explicitly define to get properties, or relationship only (what about dropdowns??)

      if (this.properties) {
        this.properties.forEach(property => {
          let property_key = property['property_key'];
          to_params[property_key] = _.cloneDeep(from_params[property_key]);
          this.$set(to_params, property_key, _.cloneDeep(from_params[property_key]));
        });
      } else if (this.relationship) {
        let relation_alias = this.$d.getRelationAlias(this.relationship);
        to_params[relation_alias] = _.cloneDeep(from_params[relation_alias]); //copy array
        this.$set(to_params, relation_alias, _.cloneDeep(from_params[relation_alias]));
      }

      //GAHHH DROPDOWNS WON"T GET extracted without the below
      /* if (this.relationships) {
          this.relationships.forEach(relationship => {
            let relation_alias = this.$d.getRelationAlias(relationship);
            to_params[relation_alias] = _.cloneDeep(from_params[relation_alias]); //copy array
            this.$set(to_params, relation_alias, _.cloneDeep(from_params[relation_alias]));
          });
        } */

      if (this.with_relationships) {
        this.with_relationships.forEach(with_relationship => {
          let relation_alias = this.$d.getRelationAliasByNames(with_relationship['from_class_name'], with_relationship['relationship_name']);
          to_params[relation_alias] = _.cloneDeep(from_params[relation_alias]); //copy array
          this.$set(to_params, relation_alias, _.cloneDeep(from_params[relation_alias]));
        });
      }

      // to_params = _.merge({}, from_params);

      return to_params;
    },
    get() {
      this.request('get');
    },
    submit() {
      this.request('submit');
    },
    request(request_mode = 'get') {
      // this.params_ = this.editable_params
      // this.$emit('update:params', this.editable_params);

      let temp_index_params = {};
      temp_index_params = Object.assign(temp_index_params, this.submit_index_params_);

      let request_type = 'read';
      if (request_mode == 'get') {
        request_type = 'read';
        temp_index_params = Object.assign(temp_index_params, { id: this.params['id'] });
      } else if (request_mode == 'submit') {
        request_type = this.editable_params['id'] ? 'update' : 'create';
        temp_index_params = Object.assign(temp_index_params, this.editable_params);
      }
      // temp_index_params = Object.assign(temp_index_params, this.params_); //don't actually need all other data, would speed up

      console.log('submit', temp_index_params);
      // this.$emit('update:params', temp_index_params); //test reactivity

      this.loading = true;
      this.$d.request(
        request_type,
        this.class_name || this.source_class_name,
        temp_index_params,
        class_data => {
          this.error_message = null;
          this.is_editable = false;

          // console.log('class_data', class_data);
          // this.$emit('update:params', class_data);

          let temp_params = _.cloneDeep(this.params); //get latest

          let extracted_params = this.extract({}, class_data);
          console.log('extracted_params', extracted_params);

          // temp_params = _.merge(temp_params, extracted_params); //cannot use this because upon removal, old ones are retained
          temp_params = _.assign(temp_params, extracted_params); //not sure why we still need assign() or merge() anyways

          // console.log('final_params', temp_params);

          this.$emit('update:params', temp_params);

          if (request_mode == 'submit') {
            this.$emit('submitted', class_data);
          }
        },
        error_message => {
          this.error_message = error_message;
        },
        () => {
          this.loading = false;
        }
      );
    },
  },
};
</script>
